import PropTypes from "prop-types"
import classNames from "classnames"
import { useAmp } from "next/amp"
import Button from "components/UI/Button"
import { ReplayIcon } from "assets/icons"
import dynamic from "next/dynamic"
import flatWavesAnimation from "assets/animation/flat-waves.json"

const Lottie = dynamic(() => import("lottie-react"), { ssr: false })
const refreshPage = () => window.location.reload()

const ErrorMessage = (props) => {
  const isAmp = useAmp()
  return (
    <div
      className={classNames(
        "flex flex-col items-center justify-center flex-1",
        props.className,
      )}>
      <h4 className="max-w-xs prose text-center fs-x-large-bold md:fs-2x-large-bold">
        {props.title} — {props.statusCode}
      </h4>

      {!isAmp && (
        <Lottie
          className={classNames("w-2/3 max-w-xs py-4 sm:py-8")}
          animationData={flatWavesAnimation}
        />
      )}

      <span className="max-w-sm prose text-center whitespace-pre-line fs-base-paragraph-regular">
        {props.description}
      </span>

      <div className="flex flex-row justify-center mt-8 sm:mt-16">
        {props.reloadButton && (
          <Button
            className="w-32 mx-2 md:mx-3"
            label="Try reloading"
            iconBefore={<ReplayIcon />}
            onClick={refreshPage}
            variant="contained"
            theme="dark"
          />
        )}

        {props.homeButton && (
          <Button
            className="w-32 mx-2 md:mx-3"
            label="Head home"
            link="/"
            variant="outlined"
            theme="light"
          />
        )}
      </div>
    </div>
  )
}
ErrorMessage.defaultProps = {
  title: "EverybodySurf",
  description: `We can't find the lineup you're looking. \nTry reloading or head to home.`,
  statusCode: 404,
}

ErrorMessage.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  reloadButton: PropTypes.bool,
  homeButton: PropTypes.bool,
  statusCode: PropTypes.number,
}

export default ErrorMessage
