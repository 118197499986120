import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

const ViewWrapper = (props) => {
  const footerPadding = "pb-12 md:pb-20"
  const headerPadding = "pt-12 md:pt-20"
  const mobilePadding = "px-4 md:px-0"

  return (
    <div
      className={classNames(
        "flex flex-col flex-grow justify-between w-full sm:w-11/12 md:max-w-md lg:max-w-lg xl:max-w-xl max:max-w-max mx-auto",
        !props.noFooterPadding && footerPadding,
        !props.noHeaderPadding && headerPadding,
        !props.noMobilePadding && mobilePadding,
        props.className,
      )}>
      {props.children}
    </div>
  )
}

ViewWrapper.propTypes = {
  noHeaderPadding: PropTypes.bool,
  noFooterPadding: PropTypes.bool,
  noMobilePadding: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default ViewWrapper
